@import '../../../client/ui/base/variables';

.ReactItemSpinner {
    .swiper-button-next,
    .swiper-button-prev {
        position: absolute;
        z-index: 100;
        top: 10%;
        height: 125px;
        width: 40px;
        border: 1px solid $gray-150;
        background-color: $white;
        opacity: 0.9;
        padding: 0;
        visibility: visible;
        color: $black;
    }

    .swiper-button-next {
        right: 0;

        &:after {
            font-size: 34px;
            font-family: fontawesome !important;
            content: '\f102';
        }
    }

    .swiper-button-prev {
        left: 0;

        &:after {
            font-size: 34px;
            font-family: fontawesome !important;
            content: '\f101';
        }
    }

    .swiper-button-disabled {
        display: none;
    }

    .spinner-image-block {
        margin: 1em 0;
        height: 146px;
        display: flex;
        justify-content: center;

        .spinner-image {
            margin: 0 auto;
            max-height: 120px;
            max-width: 120px;
        }
    }

    .slide {
        display: flex;
        flex-direction: column;
        white-space: normal;
        width: 174px;
        text-align: left;
        margin: 0 auto;

        .ImpItem_priceBox {
            display: table-cell;
            line-height: 14px;
            vertical-align: bottom;
            font-size: 14px;
            padding-bottom: 8px;
        }

        .ImpItem_cartOptionsQty {
            width: 40px;
            padding: 0;
            text-align: center;

            &:focus {
                outline: none;
            }
        }

        &:last-child {
            border-right: 0;
        }
    }

    .directReplacement {
        border: 3px solid $gray-600;
        width: 174px;
        padding: 0.75em;

        .body {
            margin: -1em;
            padding: 0.5em;
            background-color: $gray-600;
            color: $white;
            text-align: center;
            margin-bottom: 0;
        }

        &:last-child {
            border-right: 3px solid $gray-600;
        }
    }
}

.imp-item-spinner-dark {
    .ReactItemSpinner {
        .slide {
            background-color: $white;
            border-top: 5px solid $gray-200;
        }
    }
}

.imp-spinner-pagination {
    user-select: none;
}

.ImpSpinnerArrows {
    color: $blue-200;
    cursor: pointer;
    font-size: 20px;
    display: inline-block;

    i {
        vertical-align: middle;
        margin-top: -2px;
        display: inline-block;
    }

    &.disabled {
        color: $gray-400;
        cursor: default;
    }
}

.ImpSpinner-prev {
    i::before {
        margin: 0 0 0 14px;
        width: 20px;
    }

    i {
        padding-right: 10px;
    }
}

.ImpSpinner-next {
    i::before {
        margin: 0 10px 0 0;
    }

    i {
        padding-left: 10px;
    }
}
